import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

const SEO = ({ description, lang, meta, title }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            social {
              twitter
            }
          }
        }
      }
    `,
  )

  const metaTitle = title || site.siteMetadata.title
  const metaDescription = description || site.siteMetadata.description
  const titleTemplate =
    metaTitle === site.siteMetadata.title
      ? `%s`
      : `%s | ${site.siteMetadata.title}`

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      titleTemplate={titleTemplate}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:locale`,
          content: `en_US`,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:site`,
          content: `@${site.siteMetadata.social.twitter}`,
        },
        {
          name: `twitter:creator`,
          content: `@${site.siteMetadata.social.twitter}`,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO

/*
Old structured data:
=====================
MAIN PAGE
<script type="application/ld+json">{`
  {
    "@context": "https://schema.org/",
    "@type": "${type}",
    "url": "${canonical}",
    "image": {
      "@type": "ImageObject",
      "url": "${shareImage}",
      "width": "${settings.shareImageWidth}",
      "height": "${settings.shareImageHeight}"
    },
    "publisher": {
      "@type": "Organization",
      "name": "${settings.title}",
      "logo": {
        "@type": "ImageObject",
        "url": "${publisherLogo}",
        "width": 512,
        "height": 512
      }
    },
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "${settings.siteUrl}"
    },
    "description": "${description}"
  }
`}</script>

ARTICLE PAGE
<meta
  property="article:published_time"
  content={ghostPost.published_at}
/>
<meta property="article:modified_time" content={ghostPost.updated_at} />
<script type="application/ld+json">{`
  {
    "@context": "https://schema.org/",
    "@type": "Article",
    "author": {
      "@type": "Person",
      "name": "Mark Southard",
    },
    "headline": "${ghostPost.meta_title || ghostPost.title}",
    "url": "${canonical}",
    "datePublished": "${ghostPost.published_at}",
    "dateModified": "${ghostPost.updated_at}",
    "image": {
      "@type": "ImageObject",
      "url": "${shareImage}",
      "width": ${settings.shareImageWidth},
      "height": ${settings.shareImageHeight}
    },
    "publisher": {
      "@type": "Organization",
      "name": "${settings.title}",
      "logo": {
          "@type": "ImageObject",
          "url": "${publisherLogo}",
          "width": 512,
          "height": 512
      }
    },
    "description": "${ghostPost.meta_description || ghostPost.excerpt}",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "${settings.siteUrl}"
    }
  }
`}</script>
*/
